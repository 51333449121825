.service-intro img {
  width: 100%;
}
#careCharacteristics {
  text-align: center;
  padding: 2.5vw;
}
#careCharacteristics div {
  display: inline-block;
  width: calc(50% - 5vw);
  vertical-align: top;
  text-align: left;
  margin: 2.5vw;
  border-top-left-radius: 3em;
  border-top-right-radius: 3em;
  box-shadow: 0 0 0.5em 0.5em rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
#careCharacteristics div img {
  width: 100%;
}
#careCharacteristics div h3 {
  color: #FFF;
  line-height: 1;
  background-color: #e2bd23;
  padding: 0.5em 1em;
  margin: 0 0 1em;
}
#careCharacteristics div h3 small {
  display: block;
  font-size: 55%;
  margin: 0.5em 0 0;
}
#careCharacteristics div ul {
  padding: 0 2em;
}
#careCharacteristics div ul:last-of-type {
  margin: 0 0 2em;
}
#careCharacteristics div ul li {
  list-style: initial;
}
#careCharacteristics div p {
  padding: 0 1em;
}
@media (max-width: 767px) {
  #careCharacteristics div {
    width: calc(100% - 5vw);
  }
}
