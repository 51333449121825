@charset "utf-8";
h1,
h2 {
  margin: 0;
  padding: 0;
}
a {
  font-size: inherit;
  text-decoration: none !important;
}
p {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90%;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #EAEAEA;
}
.elem-hide {
  display: none !important;
}
.nomedio {
  display: block;
}
.entry-content,
.entry-content p,
.post-content,
.page-content,
.post-excerpt,
.entry-summary,
.entry-excerpt,
.excerpt,
.excerpt p,
.type-post p,
.type-page p,
.wp-block-post-content,
.wp-block-post-excerpt,
.elementor,
.elementor p {
  font-style: normal;
  font-weight: 400;
}
.entry-content h4,
.post-content h4,
.page-content h4,
.type-post h4,
.type-page h4,
.elementor h4 {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
}
a {
  font-size: inherit;
  text-decoration: none !important;
}
a:not(.forjadores-btn) {
  color: #e2bd23;
  font-weight: bold;
}
.forjadores-btn {
  display: inline-block;
  min-height: 2em;
  color: #686868;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: initial;
  text-transform: uppercase;
  background-color: #e2bd23;
  padding: 0 1.5em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.entry-content {
  font-size: 1em !important;
  line-height: 1;
}
.entry-content h1 {
  font-size: 2.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h2 {
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.entry-content h3 {
  font-size: 2em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.entry-content h4 {
  font-size: 1.75em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h5 {
  font-size: 1.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content p {
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.entry-content ul {
  margin: 0;
}
.entry-content ul li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.entry-content > img {
  width: 100% !important;
}
.entry-content > * {
  letter-spacing: initial;
  margin: 0;
}
.entry-content::after {
  clear: both;
  content: "";
  display: block;
}
.officesContainer {
  text-align: center;
  padding: 5% 20%;
}
.officesContainer h1 {
  color: #449b51;
  margin: 0 0 0.5em;
}
.officesContainer p {
  margin-bottom: 1em;
}
.paymentIntro {
  padding: 0 5%;
}
.paymentIntro img {
  display: block;
  height: auto;
  max-width: 100%;
}
.paymentDescription > p {
  font-size: 1.5em;
  line-height: 1.5;
  text-align: justify;
  padding: 2.5% 15%;
}
.paymentDescription > p:first-of-type {
  color: #FFF;
  background: url('../assets/images/Encabezados/compromiso_social.jpg') no-repeat center center;
  background-size: cover;
}
.paymentDescription > p b {
  color: #e2bd23;
}
.paymentDescription h2 {
  color: #e2bd23;
  font-weight: bolder;
  text-align: center;
  margin: 0.5em 0;
}
.paymentOptions {
  text-align: center;
  padding: 2.5%;
}
.paymentOptions div {
  display: inline-block;
  width: -webkit-calc(100% / 4);
  width: calc(100% / 4);
  vertical-align: top;
  text-align: center;
  padding: 0 2.5%;
  margin-bottom: 2em;
}
.paymentOptions div img {
  display: inline-block;
  width: auto;
  height: 1.5em;
  vertical-align: top;
}
.paymentOptions div img:first-of-type {
  display: block;
  margin: 0 auto;
}
.paymentOptions div img:nth-of-type(2),
.paymentOptions div img:nth-of-type(3) {
  height: 1.25em;
}
.paymentOptions div img:last-of-type {
  margin-bottom: 0.5em;
}
.paymentOptions div h4 {
  color: #449b51;
  margin: 0 0 0.5em;
}
.paymentOptions div h4 small {
  display: block;
  font-size: 66.67%;
}
.paymentOptions div p {
  font-size: 1em;
  background-color: #efefef;
  padding: 1em;
  -webkit-border-radius: 1em;
  border-radius: 1em;
}
.paymentOptions div p b {
  display: block;
}
.paymentOptions div .forjadores-btn {
  font-size: 0.8em;
  margin: 1em 0 0 !important;
}
.paymentOptions > a {
  margin-top: 2em;
}
.paymentOptions > p {
  font-size: 1em;
  text-align: left;
  margin-top: 2em;
}
.branchesIntro img {
  padding: 0 5%;
}
.branchesIntro h1 {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.branchesIntro p {
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5;
  padding: 1.25% 10% 0;
}
.branchesIntro p b {
  color: #e2bd23;
}
.branchesContainer {
  padding: 0 2.5%;
}
.branchesContainer div {
  display: inline-block;
  position: relative;
  width: 45%;
  vertical-align: top;
  padding: 2.5%;
  cursor: pointer;
}
.branchesContainer div p {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #FFF;
  font-size: 1.5em;
  font-family: "Ubuntu";
  text-align: center;
  text-shadow: 0 0 6px #000;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.branchesContainer div p b:first-of-type {
  text-transform: uppercase;
}
.branchesContainer div:hover p,
.branchesContainer div:focus p {
  color: #e2bd23;
}
#branchesInformation {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2014;
}
#branchesInformation div {
  display: inline-block;
  position: absolute;
  max-height: 90%;
  left: 50%;
  top: 50%;
  text-align: center;
  background-color: #449b51;
  padding: 2.5% 2.5% 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 1em;
  border-radius: 1em;
  overflow-y: auto;
}
#branchesInformation div img {
  display: inline-block;
  position: absolute;
  width: 50px;
  top: 0;
  right: 0;
  padding: 15px;
  cursor: pointer;
}
#branchesInformation div h4 {
  color: #FFF;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 0 0.5em;
}
#branchesInformation div span {
  display: inline-block;
  width: 40vw;
  vertical-align: top;
  padding: 0 2.5vw;
}
#branchesInformation div span:after {
  content: "";
  display: block;
  position: relative;
  width: 40%;
  height: 0.1em;
  background-color: #e2bd23;
  margin: 0.5em auto 1em;
}
#branchesInformation div span:last-of-type {
  margin-bottom: 2em;
}
#branchesInformation div span:last-of-type:after {
  display: none;
}
#branchesInformation div span p {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  font-size: 1.125em;
  font-weight: bold;
  margin-right: 0.5em;
}
#branchesInformation div span a:first-of-type {
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  font-size: 1.125em;
  line-height: 48px;
}
#branchesInformation div span a:last-of-type {
  display: block;
  color: #FFF;
  font-weight: normal;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .entry-content {
    font-size: 1em !important;
    line-height: 1;
  }
  .entry-content h1 {
    font-size: 28px;
    line-height: 1.25;
  }
  .entry-content h2 {
    font-size: 2.25em;
    line-height: 1.25;
  }
  .entry-content h3 {
    font-size: 2em;
    line-height: 1.25;
  }
  .entry-content h4 {
    font-size: 20px;
    line-height: 1.25;
  }
  .entry-content h5 {
    font-size: 1.5em;
    line-height: 1.25;
  }
  .entry-content p {
    font-size: 13px;
    line-height: 1.25;
  }
  .entry-content ul li {
    font-size: 1.25em;
  }
  .paymentIntro {
    padding: 0;
  }
  .paymentDescription > p {
    padding: 10% 5%;
    font-size: 15px;
  }
  .paymentOptions div {
    width: 45%;
  }
  .paymentOptions div p {
    font-size: 15px;
  }
  .paymentOptions div .forjadores-btn {
    font-size: 12px;
  }
  .branchesIntro img {
    padding: 5%;
  }
  .branchesIntro p {
    padding: 0 5%;
  }
  .branchesContainer {
    padding: 0;
  }
  .branchesContainer div {
    width: 100%;
    padding: 2.5% 0%;
  }
  .branchesContainer div p {
    font-size: 18px;
  }
  #branchesInformation div {
    padding: 5%;
  }
  #branchesInformation div img {
    width: 48px;
    padding: 12.5px;
  }
  #branchesInformation div span {
    width: 80vw;
    padding: 0;
  }
  #branchesInformation div h4 {
    padding: 0 48px;
  }
  #branchesInformation div span p,
  #branchesInformation div span a:first-of-type,
  #branchesInformation div span a:last-of-type {
    font-size: 1.25em;
  }
}
