@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
	font-size: calc(1vw * 1.15);

	margin: 0;
	padding: 0;

	color: #5B5B5F;
	font-family: 'Montserrat', sans-serif !important;

	overflow-x: hidden;
}

body * {
	margin: 0;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


@media only screen and (max-width: 768px) {
	body {
		font-size: calc(0.0287 * 100vw);
	}
}