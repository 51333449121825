.inversiones .subtitulo-2 {
    font-size: 2.25em;
    font-weight: 500;
    padding: 2.5% 25%;
    box-sizing: border-box;
}

.inversiones .invierte-con-responsabilidad .simple-text {
    padding: 0 20%;
    text-align: center;
}

.inversiones .inversiones-list {
    margin: 2.5% 0;
}

.inversiones .inversion {
    width: 50%;
}

.inversiones .inversion img {
    display: block;
    margin: auto;
}

.inversiones .inversion .forjadores-btn {
    display: block;
    margin: auto;
    margin-top: 2.5%;
}

.inversiones .inversion-lineas-verdes {
    width: 100%;
    padding: 0px 15%;
    box-sizing: border-box;
}

.inversiones .requisitos {
    width: 100%;
    padding: 0px 15%;
    box-sizing: border-box;
    margin: 2em 0 0.5em;
}


/* PAGAERE FORJADORES */
.pagare-forjadores .simple-text.full-width,
.forjadores-cede .simple-text.full-width {
    padding: 0 25%;
    text-align: center;
}

.pagare-forjadores .contact-container .info-container>h2,
.forjadores-cede .contact-container .info-container>h2 {
    font-weight: bold;
    font-size: calc(1.5em * 2);
}

.pagare-forjadores .contact-container .info-container .info-title,
.forjadores-cede .contact-container .info-container .info-title {
    font-size: 1.5em;
}

.pagare-forjadores .contact-container .info-container .highlighted,
.forjadores-cede .contact-container .info-container .highlighted {
    font-size: calc(1.5em * 1.25);

}

.pagare-forjadores .contact-container .info-container .highlighted .info-icon,
.forjadores-cede .contact-container .info-container .highlighted .info-icon {
    height: 1em;
}

.pagare-forjadores .contact-container .info-container>img,
.forjadores-cede .contact-container .info-container>img {
    margin: -1.3em 0 0.6em;
}

.pagare-forjadores .inversion-lineas-3 {
    padding: 0 15%;
    box-sizing: border-box;
    max-width: 100%;
}

.pagare-forjadores .requisitos,
.forjadores-cede .requisitos {
    margin-top: calc(2em * 2);
    padding: 0 15%;
}

@media only screen and (max-width: 768px) {
    .inversiones .subtitulo-2 {
        padding: 5% 10%;
        font-size: 2em;
    }

    .inversiones .invierte-con-responsabilidad .simple-text {
        padding: 0 10%;
        text-align: center;
    }

    .inversiones .inversiones-list {
        flex-wrap: wrap;
        gap: 30px;
    }

    .inversiones .inversiones-list .inversion {
        width: 100%;
    }

    .inversiones .inversion-lineas-verdes {
        padding: 0px 5%;
    }

    .inversiones .requisitos {
        padding: 0px 5%;
    }

    .inversiones .ipab,
    .pagare-forjadores .ipab,
    .forjadores-cede .ipab {
        padding: 0px 5%;
        box-sizing: border-box;
    }

    .inversiones .ipab .simple-text,
    .pagare-forjadores .ipab .simple-text,
    .forjadores-cede .ipab .simple-text {
        font-size: 1.5em;
        text-align: justify;
        line-height: 1.5;
    }


    .pagare-forjadores .simple-text.full-width,
    .forjadores-cede .simple-text.full-width {
        padding: 0px;
    }


    .pagare-forjadores .contact-container .info-container,
    .forjadores-cede .contact-container .info-container {
        gap: 20px;
    }

    .pagare-forjadores .inversion-lineas-3 {
        padding: 0px;
    }

    .pagare-forjadores .requisitos,
    .forjadores-cede .requisitos {
        padding: 0 5%;
    }
}