.beneficios-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;

    padding: 2.5% 15%;
}

.beneficio {
    width: 45%;
}
.beneficio img{
    width: 75%;
}
.beneficio h2{
    font-size: 1.75em;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 2.5%;
}
.beneficio p{
    margin-bottom: 2.5%;
    font-size: 1em;
}
.beneficio button{
    font-weight: 700;
    background-color: #e2bd23;
    color: #5B5B5F;
    text-transform: uppercase;
    padding: 0px 1.5em;
    font-size: 1em;
    border: none;
    line-height: 48px;
}

@media only screen and (max-width: 768px) {
    .beneficios-list{
        padding: 2.5% 5%;
    }
    .beneficio {
        width: 100%;
        margin-top: 1em;
    }
    .beneficio img{
        width: 50%;
        display: block;
        margin: auto;
    }
    .beneficio h2, .beneficio p{
        text-align: center;
    }
    .beneficio button{
        display: block;
        margin: auto;
    }
    .recomienda-y-gana{
        padding: 2.5% 5%;
    }
}