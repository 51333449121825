@charset "utf-8";
h1,
h2 {
  margin: 0;
  padding: 0;
}
a {
  font-size: inherit;
  text-decoration: none !important;
}
p {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90%;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #EAEAEA;
}
.elem-hide {
  display: none !important;
}
.nomedio {
  display: block;
}
body {
  width: 100%;
  height: 100%;
  min-width: 320px;
  min-height: 320px;
  color: #5B5B5F;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: initial;
  line-height: 1;
  margin: 0;
  padding: 0;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
}
.site-content {
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: 1em !important;
  line-height: 1;
}
.site-content h1 {
  font-size: 2.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.site-content h2 {
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.site-content h3 {
  font-size: 2em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.site-content h4 {
  font-size: 1.75em;
  line-height: 1.25;
  letter-spacing: initial;
}
.site-content h5 {
  font-size: 1.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.site-content p {
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.site-content ul {
  margin: 0;
}
.site-content ul li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.site-content > img {
  width: 100% !important;
}
.site-content > * {
  letter-spacing: initial;
  margin: 0;
}
.site-content .headsite {
  position: relative;
}
.site-content .headsite .textcont {
  position: absolute;
  right: 5%;
  top: 32%;
  width: 32%;
}
.site-content .headsite .textcont .textfix {
  width: 100%;
}
.site-content .headsite .textcont .textfix .texthead {
  font-family: 'Montserrat';
  color: #885098;
  text-align: inherit;
  letter-spacing: normal;
  font-size: 2.5em !important;
  line-height: 1.25 !important;
}
.site-content .headsite .textcont .textfix .conocebtn {
  white-space: nowrap;
  font-size: 1em !important;
  font-weight: bold !important;
  line-height: 50px !important;
  padding: 0 1.5em !important;
  background: #449b51;
  opacity: 1;
  box-shadow: none;
  border: 0px solid #000000;
  border-radius: 0px;
  font-family: 'Montserrat';
  line-height: 1.5;
  text-align: center;
  letter-spacing: normal;
  word-spacing: normal;
  width: min-content;
}
.site-content .headsite .textcont .textfix .conocebtn a {
  text-decoration: none;
  color: #ffffff;
}
.site-content .headsite img {
  width: 100%;
}
.homeIntro {
  text-align: center;
  padding: 2.5% 20%;
}
.service-preview {
  max-width: initial;
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  text-align: center;
  padding: 1.25% 2.5% 1.25% 15%;
  border-top: 0.5em solid #449b51;
}
.service-preview h2 {
  position: absolute;
  width: 22%;
  left: 0;
  top: 0;
  color: #FFF;
  line-height: 1.25;
  background-color: #449b51;
  padding: 0.5em 1em;
  margin: 0;
  -webkit-border-bottom-right-radius: 1em;
  border-bottom-right-radius: 1em;
  font-size: 25px !important;
  font-style: normal;
  font-weight: 500;
}
.service-preview h2 a {
  display: inline-block;
  display: none;
  position: absolute;
  left: 50%;
  top: 100%;
  color: #e2bd23;
  font-size: 50%;
  line-height: 48px;
  white-space: nowrap;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.service-preview .container > a {
  display: inline-block;
  width: calc(100% / 4 - 8vw);
  vertical-align: top;
  padding: 1.25vw;
  margin: 0 0.5vw 1.25vw;
  border: 1px solid transparent;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.service-preview .container > a img {
  display: block;
  width: 12.5em;
  height: 12.5em;
  -o-object-fit: contain;
  object-fit: contain;
  margin: 0 auto;
}
.service-preview .container > a p {
  font-size: 0.8em;
}
.service-preview .container > a p big {
  display: block;
  font-weight: bold;
  margin-bottom: 0.25em;
}
.service-preview .container > a p b {
  display: block;
  color: #e2bd23;
  text-transform: uppercase;
  margin-top: 1em;
  white-space: nowrap;
}
.home-banner {
  position: relative;
}
.home-banner div {
  position: absolute;
  width: 35%;
  left: 50%;
  top: 50%;
  text-align: center;
  padding: 0 5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.home-banner div h2 {
  color: #000;
  font-size: 2.5em;
  letter-spacing: 0.2em;
  margin: 0 0 0.5em;
}
#tipsBanner div h2 big {
  color: #004623;
}
#tipsBanner div h2 b {
  color: #449b51;
}
.forjadores-btn {
  display: inline-block;
  min-height: 2em;
  color: #686868;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: initial;
  text-transform: uppercase;
  background-color: #e2bd23;
  padding: 0 1.5em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
#covidBanner .forjadores-btn {
  color: #e2bd23;
  background-color: #000;
}
#homeFooter {
  display: block;
  letter-spacing: -1em;
  padding: 2.5% 10%;
}
#homeFooter img {
  display: inline-block;
  width: 7.5em;
  vertical-align: middle;
}
#homeFooter p {
  display: inline-block;
  width: calc(100% - 8em);
  vertical-align: middle;
  letter-spacing: initial;
  color: #686868;
  line-height: 1.25;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1.25em;
  font-style: normal;
}
#homeFooter p a {
  color: #e2bd23;
  font-weight: bold;
}
#complaintBanner {
  position: relative;
}
#complaintBanner div {
  position: absolute;
  width: 60vw;
  left: 0;
  top: 50%;
  padding: 0 5vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#complaintBanner div h2 {
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 1.25;
  padding-right: 1em;
  margin: 0 0 0.25em;
  font-family: "Ubuntu", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 2.25em;
  font-style: normal;
}
#complaintBanner div h2 big {
  display: block;
  position: relative;
  color: #449b51;
  font-size: 200%;
  font-weight: bold;
  text-align: center;
  text-shadow: 6px 6px 6px rgba(0, 0, 0, 0.5);
  line-height: 0.9;
}
#complaintBanner div h2 b {
  display: block;
  position: relative;
  color: #FFF;
  font-weight: inherit;
}
#complaintBanner div h2::before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% + 5vw);
  height: 1.7em;
  left: -5vw;
  top: 1.25em;
  background-color: #004623;
}
#complaintBanner div p {
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-size: 1em;
  font-style: normal;
}
#complaintBanner div p big {
  display: block;
  font-weight: normal;
  text-align: left;
  margin: 0.5em 0;
}
#complaintBanner div p a {
  color: #000;
  text-decoration: underline !important;
}
#tipsBanner img,
#covidBanner img,
#complaintBanner img {
  width: 100%;
}
#investmentOptions .container > a p {
  color: #C00000;
  font-weight: 900;
}
#investmentOptions .container > a p big b {
  color: #449b51;
  font-weight: 900;
  letter-spacing: -0.05em;
  margin: 0;
  display: inline-block;
}
#investmentOptions .container > a p small {
  display: inline-block;
  vertical-align: top;
}
#investmentOptions .container > a p big {
  white-space: nowrap;
}
.entry-content {
  font-size: 1em !important;
  line-height: 1;
}
.entry-content h1 {
  font-size: 2.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h2 {
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.entry-content h3 {
  font-size: 2em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.entry-content h4 {
  font-size: 1.75em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h5 {
  font-size: 1.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content p {
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.entry-content ul {
  margin: 0;
}
.entry-content ul li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.entry-content > img {
  width: 100% !important;
}
.entry-content > * {
  letter-spacing: initial;
  margin: 0;
}
.entry-content::after {
  clear: both;
  content: "";
  display: block;
}
#buroIntro img {
  padding: 0 10%;
  display: block;
  height: auto;
  width: 80%;
}
#buroContainer {
  text-align: center;
  padding: 0 0 5vw;
}
#buroContainer p {
  font-size: 1.5em;
  text-align: justify;
  padding: 2.5vw 20%;
}
#buroContainer p b {
  color: #449b51;
}
#buroContainer p big {
  display: block;
  color: #449b51;
  font-weight: bold;
  margin: 0 0 0.5em;
}
#buroContainer p small {
  display: block;
  font-size: 75%;
  margin: 1em 0 0;
}
#buroContainer p a {
  color: #e2bd23;
  font-weight: bold;
}
#covidInfoIntro {
  padding: 0 5%;
}
#covidInfoClean {
  letter-spacing: -1em;
  padding: 5% 7.5% 0;
}
#covidInfoClean > img {
  display: inline-block;
  width: 40%;
  vertical-align: top;
  padding: 0 2.5%;
}
#covidInfoClean > div {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  letter-spacing: initial;
  padding: 0 2.5%;
}
#covidInfoClean > div h2 {
  position: relative;
  line-height: 1;
  padding: 0 0 0.5em !important;
  margin: 0 0 0.5em !important;
}
#covidInfoClean > div h2:after {
  content: "";
  display: block;
  position: absolute;
  width: 6.75em;
  height: 0.1em;
  left: -5%;
  bottom: 0;
  background-color: #e2bd23;
}
#covidInfoClean > div h2 b {
  color: #e2bd23;
}
#covidInfoClean > div p {
  line-height: 1.5;
}
#covidInfoClean > div p b {
  color: #449b51;
}
#covidInfoClean > p {
  text-align: center;
  margin-bottom: 2.5%;
}
#covidInfoClean > p img {
  display: block;
  margin: 1em auto;
}
#covidInfoClean span {
  display: inline-block;
  width: 28.33%;
  vertical-align: top;
  padding: 2.5%;
}
#covidInfoClean span img {
  display: inline-block;
  width: 30%;
  vertical-align: middle;
}
#covidInfoClean span p {
  display: inline-block;
  width: 66%;
  vertical-align: middle;
  font-size: 1em;
  padding-left: 4%;
}
#covidInfoClean span p strong {
  display: block;
}
#covidInfoRecommendations {
  background: url("../assets/images/fondo-recomendaciones-contra-covid.png") no-repeat right top;
  background-size: cover;
  padding: 12.5% 10% 5%;
}
#covidInfoRecommendations h2 {
  color: #FFF;
  text-align: center;
  font-weight: bold;
  margin: 0 0 2.5%;
}
#covidInfoRecommendations h2 b {
  display: block;
  color: rgba(0, 0, 0, 0.5);
}
#covidInfoRecommendations div {
  display: inline-block;
  position: relative;
  width: 40%;
  vertical-align: middle;
  padding: 2.5% 5%;
}
#covidInfoRecommendations div img {
  position: absolute;
  width: 25%;
  height: 95%;
  left: 5%;
  top: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left center;
  object-position: left center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#covidInfoRecommendations div p {
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1em 1em 1em 27.5%;
  -webkit-border-radius: 5em;
  border-radius: 5em;
}
.product-intro {
  position: relative;
}
.product-intro img {
  width: 100%;
}
.product-intro h1 {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.product-description {
  padding: 5vw 5vw;
}
.product-description > p {
  text-align: center;
  padding: 0 2.5vw 5vw;
  margin: 0 0 5vw;
  border-bottom: 0.2em solid #885098;
}
.product-description > p b {
  color: #885098;
}
.product-description div {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
  padding: 0 2.5%;
}
.product-description div h2 {
  color: #000;
  margin: 0 0 0.5em;
}
.product-description div h2 small {
  display: block;
  color: #686868;
  font-size: 40%;
  font-weight: normal;
}
.product-description div h2 b {
  color: #885098;
}
.product-description div p {
  margin: 0.5em 0;
}
.product-description div p b {
  color: #449b51;
}
.product-description div p big {
  color: #885098;
  font-weight: bold;
}
.product-description div p:last-of-type {
  margin: 1.5em 0 0;
}
.product-description div p small {
  font-size: 75%;
}
.product-description img {
  display: inline-block;
  width: 35%;
  vertical-align: middle;
  padding: 2.5%;
}
.product-quote {
  letter-spacing: -1em;
  margin: 2.5% 0 0;
}
.product-quote h2 {
  width: 100%;
  max-width: initial;
  color: #DDD !important;
  font-size: 2.25em;
  font-weight: normal;
  text-align: center;
  letter-spacing: initial;
  background: url("../assets/images/Encabezados/fondo-banner-campana-credito.jpg") no-repeat center center !important;
  background-size: cover;
  padding: 2.5vw;
  margin: 0;
}
.product-quote h2 b {
  color: #FFF !important;
}
.product-quote > p {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  font-size: 1.5em;
  font-weight: normal;
  letter-spacing: initial;
  line-height: 1.25;
  padding: 0 2.5vw 0 5vw;
}
.product-quote > p big {
  display: block;
  color: #885098 !important;
  font-size: 200%;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2.5%;
}
.product-quote > p a {
  display: block;
  min-height: 1.5em;
  color: #449b51;
  font-size: 125%;
  font-weight: bold;
  line-height: 50px;
}
.product-quote > p a:first-of-type {
  display: none;
}
.product-quote > p a:last-of-type {
  margin-bottom: 0.5em;
}
.product-quote p > img {
  margin: -1.3em 0 0;
}
.product-quote p a img {
  display: inline-block;
  height: 1em;
  vertical-align: middle;
}
.product-legal {
  padding: 2.5vw;
}
.product-legal h2 {
  color: #000;
  padding: 2.5vw 2.5vw 0;
  margin: 0;
  border-top: 0.1em solid #885098;
}
.product-legal p {
  padding: 2.5vw;
}
#uneContainer {
  width: 70%;
  max-width: initial;
  text-align: center;
  padding: 5% 15%;
}
#uneContainer p {
  font-size: 1.5em;
}
#uneContainer p b {
  display: block;
  color: #449b51;
  margin: 0 0 1em;
}
#uneContainer p a {
  color: #e2bd23;
}
#uneContainer p small {
  display: block;
  margin: 1em 0 2em;
}
#uneContainer > a {
  color: #449b51;
  font-size: 1.25em;
  line-height: 1.25;
}
#consultIntro img {
  padding: 0 10%;
  width: 80%;
}
#consultInfo {
  padding: 0 0 5%;
}
#consultInfo p {
  padding: 0 10%;
}
#consultInfo p a {
  color: #e2bd23;
  font-weight: bold;
}
#consultTheft {
  background-color: #efefef;
  padding: 5% 10%;
}
#consultTheft p big {
  display: block;
  color: #e2bd23;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.5%;
}
#privacyContainer {
  text-align: center;
  padding: 5%;
}
#privacyContainer p {
  font-size: 0.9em;
  text-align: left;
}
#privacyContainer p strong {
  color: #449b51;
}
#privacyContainer p span {
  margin-left: 2.5%;
}
#privacyContainer p a {
  color: #449b51;
  text-decoration: underline !important;
}
#privacyContainer > a {
  display: inline-block;
  color: #686868;
  text-align: center;
  line-height: 1.25;
  margin: 2.5% 0 0;
}
#privacyContainer > a b {
  display: block;
  border-bottom: 0.2em solid #449b51;
}
.inputGroup {
  background-color: #fff;
  margin: 0px 0px 30px 0px;
}
.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  font-size: 18px;
  color: #3C454C;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  content: '';
  opacity: 0;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: '';
  border: 2px solid #D1D7DC;
  background-repeat: no-repeat;
  background-position: 2px 3px;
  z-index: 2;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: #009E59;
}
.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #009E59;
  border-color: #009E59;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
@media (max-width: 767px) {
  #buroIntro img {
    padding: 5%;
  }
  #buroContainer p {
    font-size: 16px;
    padding: 0 5% 5%;
  }
  #buroContainer .forjadores-btn {
    display: block;
    width: 90vw;
    font-size: 1.25em;
    line-height: 1.5;
    padding: 1em;
    margin: 0 auto;
  }
  #covidInfoClean span p {
    font-size: 0.7em;
  }
  .product-description {
    padding-bottom: 0;
  }
  .product-description div {
    width: 100%;
    padding: 0;
  }
  .product-description img {
    width: 100%;
    padding: 0;
  }
  .product-legal {
    padding: 2.5vw;
  }
  .product-legal h2 {
    padding: 2.5vw 0 0;
    margin: 0;
  }
  .product-legal p {
    padding: 0;
  }
  #uneContainer {
    width: 90%;
    max-width: initial;
    text-align: center;
    padding: 5%;
  }
  #uneContainer p {
    font-size: 0.8em;
  }
  #uneContainer p b {
    display: block;
    color: #449b51;
    margin: 0 0 1em;
  }
  #uneContainer p a {
    color: #e2bd23;
  }
  #uneContainer p small {
    display: block;
    margin: 1em 0 2em;
  }
  #uneContainer > a {
    color: #449b51;
    font-size: 1.25em;
    line-height: 1.25;
  }
}
