.menu-modal {
    position: fixed;
    top: 0px;
    left: 110vw;
    width: 100vw;
    height: 100vh;
    background: url(https://www.forjadores.mx/wp-content/uploads/2020/06/fondo_menu.png) no-repeat left bottom !important;
    padding: 60px 115px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.menu-modal.open * {
    opacity: 0;
}

.menu-modal.open {
    animation: openMenu 0.5s ease 0s forwards;
}

.menu-modal.open * {
    opacity: 0;
    animation: openMenuItems 0.2s ease 0.5s forwards;
}

.menu-modal.open .close-container {
    right: 0px;
}

.menu-modal.close {
    left: 0vw;
    animation: closeMenu 0.5s ease 0.2s forwards;
}

.menu-modal.close * {
    animation: closeMenuItems 0.2s ease 0s forwards;
}

@keyframes openMenu {
    0% {
        left: 110vw;
        opacity: 0.5;
    }

    100% {
        opacity: 1;
        left: 0px;
    }
}

@keyframes openMenuItems {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes closeMenu {
    0% {
        left: 0px;
        opacity: 1;
    }

    100% {
        opacity: 0;
        left: 110vw;
    }
}

@keyframes closeMenuItems {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.menu-modal .close-container {
    position: fixed;
    right: -100%;
    top: 0px;

    height: 48px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    width: 100%;

    padding: 0px 38px;

    cursor: pointer;
}

.menu-modal .close-container .isotipo {
    opacity: 0 !important;
    width: 48px;
}

.menu-modal .close-container .close-button {
    background-color: transparent;
    border: none;
}

.menu-modal .close-container span {
    color: #fff;
    font-size: 1em;
    font-weight: 500;
}

.menu-modal .close-container polygon {
    fill: #fff;
}

.menu-modal ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-modal ul li {
    /* min-height: 48px; */
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 21px;
}

.menu-modal ul li:last-child {
    margin-bottom: 0;
}

button.btn-list {
    font-size: 1.5em;
    line-height: 1em;
    font-weight: 400;
    color: #fff;
    opacity: 0.75 !important;
    text-decoration: none;
    transition: 0.3s;
    background-color: transparent;
    border: none;
    padding: 0px;
    text-align: start;
}

.active button.btn-list {
    opacity: 1 !important;
    font-weight: 600;
    transition: 0.3s;
}

li:hover button.btn-list {
    opacity: 1 !important;
    transition: 0.3s;
}

button.btn-list:hover {
    opacity: 1 !important;
    transition: 0.3s;
}


.menu-modal ul li svg {
    opacity: 0.75 !important;
    transition: 0.3s;
    cursor: pointer;
}

.menu-modal ul li:hover svg {
    opacity: 1 !important;
    transition: 0.3s;
}

.menu-modal ul li.active svg {
    opacity: 1 !important;
    transition: 0.3s;
}

.menu-modal .submenu {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.menu-modal .submenu button {
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .menu-modal {
        background: #fff !important;
        padding: 0px 0px 60px 0px;
    }

    .menu-modal .close-container {
        position: relative;
        border-bottom: 1px solid #686868;
        padding: 0px 1em;
    }

    .menu-modal .close-container .isotipo {
        opacity: 1 !important;
    }

    .menu-modal .close-container span {
        color: #686868;
        font-size: 1.5em;
    }

    .menu-modal .close-container svg {
        width: 1.5em;
        height: 1.5em;
    }

    .menu-modal .close-container svg polygon {
        fill: #686868;
    }

    .menu-modal .menu {
        margin-top: 20px;
    }

    .menu-modal ul li {
        justify-content: center;
    }

    button.btn-list {
        color: #686868;
        text-align: center;
        font-size: 1.5em;
    }

    .menu li svg polygon {
        fill: #686868;
    }

    .submenu {
        background-color: #d3d3d2;
        padding: 15px 0px !important;
        margin-bottom: 21px;
    }

    .submenu .btn-list {
        font-size: 18px !important;
    }
}