.entry-content {
  font-size: 1em !important;
  line-height: 1;
}
.entry-content h1 {
  font-size: 2.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h2 {
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
}
.entry-content h3 {
  font-size: 2em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.entry-content h4 {
  font-size: 1.75em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content h5 {
  font-size: 1.5em;
  line-height: 1.25;
  letter-spacing: initial;
}
.entry-content p {
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.entry-content ul {
  margin: 0;
}
.entry-content ul li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.entry-content > img {
  width: 100% !important;
}
.entry-content > * {
  letter-spacing: initial;
  margin: 0;
}
.entry-content::after {
  clear: both;
  content: "";
  display: block;
}
#groupIntro > img {
  width: 100%;
}
#groupIntro p {
  font-size: 1.25em;
  font-weight: normal;
  text-align: center;
  line-height: 1.25;
  padding: 2.5% 5%;
}
.cta-credit {
  display: inline-block;
  color: #449b51 !important;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  float: right;
  margin-top: 1.5em;
}
.cta-credit .forjadores-btn {
  font-size: 75%;
  padding: 0 7em;
  margin: 0.5em 0 0;
}
#groupInformation h2 {
  width: 100%;
  max-width: initial;
  color: #FFF;
  font-size: 2.25em;
  font-weight: normal;
  text-align: center;
  background: url("../assets/images/Encabezados/Grupal_fondo-obtenuncredito.png") no-repeat center center;
  background-size: cover;
  padding: 2.5% 0%;
  margin: 0;
}
#groupInformation h2 > small {
  display: block;
  font-weight: bold;
  margin-bottom: 1em;
}
#groupInformation h2 > small small {
  display: inline-block;
  vertical-align: top;
}
#groupInformation h2 b {
  color: #e2bd23;
}
#groupInformation h2 .cta-credit {
  display: block;
  float: initial;
}
#groupInformation div {
  display: inline-block;
  position: relative;
  width: 40%;
  vertical-align: top;
  padding: 0 5%;
  margin: 5% 0;
}
#groupInformation div:first-of-type {
  padding-right: 5%;
}
#groupInformation div:first-of-type:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.5em;
  height: 100%;
  top: 0;
  right: -0.25em;
  background-color: #449b51;
  -webkit-border-radius: 1em;
  border-radius: 1em;
}
#groupInformation div:last-of-type {
  padding-left: 5%;
}
#groupInformation div h3 {
  color: #e2bd23;
  margin: 0 0 2.5%;
}
#groupInformation div p {
  font-size: 1.25em;
  font-weight: normal;
  line-height: 1.25;
}
#groupInformation div p:nth-of-type(2) {
  margin-top: 2em;
}
#groupInformation div p br {
  margin-bottom: 2.5%;
}
#groupInformation div p b,
#groupInformation div p a {
  color: #e2bd23;
}
#groupInformation div img {
  display: block;
  width: 80%;
  margin: 5% auto 0;
}
#groupInformation > p {
  font-size: 1.25em;
  font-weight: normal;
  text-align: center;
  line-height: 1.25;
  margin: 0 0 1em;
}
#groupInformation > p:first-of-type {
  position: relative;
  width: 100vw;
  top: initial;
  right: initial;
  color: #FFF;
  font-size: 1.5em;
  text-align: center;
  background-color: #449b51;
  padding: 1em 0;
  margin: 0 0 2em;
}
#groupInformation > p:first-of-type a {
  color: #e2bd23;
  font-weight: bold;
}
#groupInformation > p:nth-of-type(2),
#groupInformation > p:nth-of-type(3) {
  padding: 0 5vw;
  text-align: left;
}
#groupInformation > p a {
  color: #e2bd23;
  font-weight: bold;
}
#groupInformation .cta-credit {
  display: block;
  float: initial;
}
.contact-container {
  padding: 2.5% 10.5%;
}
.info-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.info-container h2 {
  color: #e2bd23;
  line-height: 1;
  font-weight: 600;
  font-size: 2.25em;
}
.info-container big {
  display: block;
  color: #e2bd23;
  font-size: 200%;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2.5%;
}
.info-container big.forjadoras {
  color: #885098;
}
.info-container .highlighted {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.25em;
  font-weight: bold;
  color: #449b51;
  text-decoration: none;
  line-height: 1.5;
}
.info-container .info-title {
  color: #686868;
  font-size: 1.25em;
}
.info-container .info-icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.info-container.forjadoras big {
  display: block;
  color: #885098;
  font-size: 200%;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2.5%;
}
.form-container {
  width: 50%;
}
.form-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.form-container .form-item {
  width: 50%;
}
.form-container label {
  font-size: 1em;
  color: #686868;
  display: block;
  margin-bottom: 3px;
}
.form-container .erf-required {
  color: #f00;
  font-weight: 400;
  margin-left: 3px;
}
.form-container input,
.form-container select,
.form-container textarea {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #686868;
  font-weight: 500;
  background-color: rgba(226, 189, 35, 0.5);
  border: none;
  padding: 6px 10px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.form-container.forjadoras input,
.form-container.forjadoras select,
.form-container.forjadoras textarea {
  width: 100%;
  height: 40px;
  font-size: 1em;
  color: #686868;
  font-weight: 500;
  background-color: #d3d3d2;
  border: none;
  padding: 6px 10px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.credit-quote {
  margin: 5% 0 0 !important;
}
.credit-quote h2 {
  padding: 5vw;
  width: 100%;
  max-width: initial;
  color: #FFF;
  font-size: 2.25em;
  font-weight: normal;
  text-align: center;
  background: url('../assets/images/Encabezados/Individual_fondo_yatienes.png') no-repeat center center;
  background-size: cover;
  padding: 2.5vw;
  margin: 0;
}
.credit-quote h2 b {
  color: #e2bd23;
}
.credit-quote > p {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.25;
  padding-left: 5%;
}
.credit-quote > p big {
  display: block;
  color: #e2bd23;
  font-size: 200%;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2.5%;
}
.credit-quote > p a {
  display: block;
  min-height: 1.5em;
  color: #449b51;
  font-size: 125%;
  font-weight: bold;
  line-height: 50px;
}
.credit-quote > p a:last-of-type {
  margin-bottom: 0.5em;
}
.credit-quote > p img {
  margin: -1.3em 0 0;
}
.credit-quote p a img {
  display: inline-block;
  height: 1em;
  vertical-align: middle;
}
.credit-quote .erf-container {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  padding: 5%;
}
.credit-quote .erf-container .erf-form .form-group {
  padding: 0;
  margin-bottom: 1em;
}
.credit-quote .rendered-form {
  margin: 0 !important;
}
.credit-quote .erf-two-columns .form-group label {
  font-size: 1.125em;
  padding: 0 !important;
  margin: 0 0 0.5em !important;
}
.credit-quote .erf-two-columns .form-group input,
.credit-quote .erf-two-columns .form-group select {
  height: 40px;
  font-size: 1.25em;
  line-height: 40px;
}
.credit-quote .erf-two-columns .form-group {
  padding: 0;
  margin-bottom: 2.5%;
}
.credit-quote .erf-two-columns:last-of-type .form-group {
  display: block;
  width: 100% !important;
  margin: 0 0 2.5%;
}
.credit-quote .erf-two-columns .form-group label {
  color: #686868;
  font-size: 1em;
  font-weight: normal;
  padding: 0;
  margin-bottom: 1.25%;
}
.credit-quote .erf-two-columns .form-group input,
.credit-quote .erf-two-columns .form-group select {
  height: 48px;
  min-height: 2em;
  color: #686868;
  font-weight: 500;
  line-height: 48px;
  background-color: rgba(226, 189, 35, 0.5);
  padding: 0 1em;
  border: none;
}
.credit-quote .erf-two-columns .form-group textarea {
  color: #686868;
  font-weight: 500;
  background-color: rgba(226, 189, 35, 0.5);
  border: none;
}
.credit-quote .intl-tel-input.separate-dial-code .selected-dial-code {
  font-size: 0.7em;
  padding-left: 25px;
}
.credit-quote .erf-submit-button button {
  font-size: 1em;
  font-weight: 500;
  text-decoration: none !important;
}
#groupSteps {
  text-align: center;
  margin: 0 0 5vw;
}
#groupSteps a {
  color: #FFF;
  text-transform: uppercase;
  background-color: #449b51;
  margin: 0 0.5em;
}
#groupSteps div {
  padding: 2.5%;
}
#groupSteps div span {
  display: inline-block;
  width: 25%;
  vertical-align: bottom;
}
#groupSteps div span p {
  color: #e2bd23;
  font-size: 1.25em;
  line-height: 1.25;
  text-align: center;
  padding: 0 0.5em;
}
#groupSteps div span p big {
  display: block;
  font-size: 200%;
  font-weight: bold;
}
.bann {
  width: 100%!important;
}
.service-intro > h1 {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.service-intro h2 {
  text-align: center;
  margin-top: 2.5vw;
}
.service-intro p {
  display: block;
  text-align: center;
  padding: 0 25vw;
}
.service-intro > img {
  display: block;
  width: 50%;
  margin: 2.5vw auto;
}
.service-description span {
  display: block;
  color: #FFF;
  text-align: center;
  background: url("../assets/images/Encabezados/Grupal_fondo-obtenuncredito.png") no-repeat center center;
  background-size: cover;
  padding: 2.5vw;
}
.service-description div {
  display: inline-block;
  position: relative;
  width: 40%;
  padding: 2.5vw 5%;
  vertical-align: top;
}
.service-description div:first-of-type {
  padding-right: 5%;
}
.service-description div:first-of-type:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.5em;
  height: 80%;
  left: 100%;
  top: 2.5vw;
  background-color: #449b51;
  border-radius: 1em;
  transform: translateX(-50%);
}
.service-description div:last-of-type {
  padding-right: 5%;
}
.service-description div h3 {
  color: #e2bd23;
}
.service-description div li:before {
  content: "•";
  display: inline-block;
  position: absolute;
  top: -0.25em;
  right: 100%;
  color: #e2bd23;
  font-size: 200%;
  line-height: 1;
}
.service-description > p {
  padding: 0 5vw;
}
.service-description > p big {
  display: block;
  color: #449b51;
  font-weight: bold;
  text-align: center;
  margin: 0 0 0.5em;
}
.service-quote {
  margin: 2.5% 0 0 !important;
}
.service-quote h2 {
  width: 100%;
  max-width: initial;
  color: #FFF;
  font-size: 2.25em;
  font-weight: normal;
  text-align: center;
  background: url("../assets/images/Encabezados/Individual_fondo_yatienes.png") no-repeat center center;
  background-size: cover;
  padding: 2.5vw;
  margin: 0;
}
.service-quote h2 b {
  color: #e2bd23;
}
.service-quote h2 > p {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.25;
  padding-left: 5%;
}
.service-quote h2 > p big {
  display: block;
  color: #e2bd23;
  font-size: 200%;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2.5%;
}
.service-quote h2 > p a {
  display: block;
  min-height: 1.5em;
  color: #449b51;
  font-size: 125%;
  font-weight: bold;
  line-height: 50px;
}
.service-quote h2 > p a:first-of-type {
  display: none;
}
.service-quote h2 > p a:last-of-type {
  margin-bottom: 0.5em;
}
.service-quote h2 > p img {
  margin: -1.3em 0 0;
}
.service-quote h2 p a img {
  display: inline-block;
  height: 1em;
  vertical-align: middle;
}
.terms-glossary {
  padding: 2.5% 5% 0;
}
.terms-glossary h1 {
  text-align: center;
  line-height: 1;
  margin: 0 0 0.5em;
}
.terms-glossary h1 img {
  display: inline-block;
  height: 1em;
  vertical-align: middle;
}
.terms-glossary p {
  margin-bottom: 5%;
}
.terms-glossary p b {
  color: #e2bd23;
}
.terms-glossary p i {
  color: #449b51;
  font-style: normal;
}
@media only screen and (max-width: 768px) {
  .entry-content h1 {
    font-size: 1.8em;
    line-height: 1.25;
    letter-spacing: initial;
  }
  .entry-content h2 {
    font-size: 1.25em;
  }
  .entry-content h3 {
    font-size: 1em;
  }
  .entry-content p {
    font-size: 0.8em;
  }
  .entry-content ul li {
    font-size: 0.8em;
    line-height: 1.25;
  }
  .contact-container {
    flex-direction: column;
    padding: 35px;
  }
  .contact-container .info-container,
  .contact-container .form-container {
    width: 100%;
  }
  #groupIntro > img {
    height: 40vw;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: right center;
    object-position: right center;
  }
  #groupIntro p {
    padding: 5vw;
  }
  #groupIntro div {
    padding: 5% 1.25% 0;
  }
  #groupIntro div span p {
    font-size: 1em;
    padding: 0 1.25%;
  }
  #groupInformation h2 {
    width: 90%;
    padding: 5%;
    margin-bottom: 2.5%;
    font-size: 1.25em;
  }
  #groupInformation div {
    width: 90%;
    padding: 5% !important;
    margin: 0 !important;
  }
  #groupInformation div:first-of-type:after {
    width: 0px;
    height: 0px;
  }
  #groupInformation div p {
    font-size: 15px;
  }
  #groupInformation div h3 {
    font-size: 20px;
  }
  #groupInformation > p:nth-of-type(2),
  #groupInformation > p:nth-of-type(3) {
    font-size: 0.8em;
  }
  #groupBenefits div {
    width: 100%;
    padding: 5% 0;
  }
  #groupBenefits img {
    width: 100%;
  }
  #groupSteps div {
    white-space: nowrap;
    overflow-x: auto;
  }
  #groupSteps div span {
    width: 45%;
    white-space: initial;
  }
  #groupSteps div span p {
    font-size: 0.6em;
  }
  #groupSteps a {
    margin: 1em 1em 0;
  }
  .credit-quote h2 {
    width: 90%;
    padding: 5%;
    font-size: 1.2em;
  }
  .info-container big {
    font-size: 100%;
  }
  .info-container .highlighted {
    font-size: 0.8em;
    overflow-wrap: break-word;
  }
  .service-intro h2 {
    font-size: 1.2em;
  }
  .service-intro p {
    padding: 0 5vw;
    font-size: 0.8em;
  }
  .service-intro > img {
    width: 90%;
  }
  .service-description div {
    width: 90%;
    padding: 5% !important;
    margin: 0 !important;
  }
  .service-description div:first-of-type:after {
    width: 0px;
    height: 0px;
  }
  .service-description div p {
    font-size: 13px;
  }
  .service-description div h3 {
    font-size: 20px;
  }
  .service-description > p {
    padding: 0 5vw;
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 600px) {
  .contacto .info-container h2 {
    font-size: 30px !important;
  }
  .contacto .info-container .highlighted,
  .contacto .info-container .info-title {
    font-size: 14px !important;
  }
  .contacto .form-container {
    margin-top: 35px;
  }
  .form-container .row {
    flex-direction: column;
  }
  .form-container .row .form-item {
    width: 100%;
  }
  .form-container label,
  .form-container input,
  .form-container select,
  .form-container textarea,
  .form-container .forjadores-btn {
    font-size: 14px !important;
  }
}
