button {
	cursor: pointer;
	font-family: 'Montserrat', sans-serif !important;
	font-size: inherit;
}

.row {
	display: flex;
	align-items: center;
	gap: 10px;
}

.text-2xl {
	font-size: calc(1em * 1.5);
}

.font-bold {
	font-weight: bold;
}

.accordion-container {
	overflow: hidden;
	width: 100%;
	max-width: -webkit-fill-available;
	/* transform-origin: top; */
	transition: 0.5s;
}

.encabezado {
	width: 100%;
	box-sizing: border-box;
	padding: 0px 15%;
}

.encabezado img {
	width: 100%;
}

.simple-text {
	font-size: 1.25em;
	font-weight: 400;
	margin-bottom: 22px;
}

.simple-text b {
	color: #e2bd23;
}

.simple-text.green-dots b {
	color: #449b51;
}

.simple-text a {
	color: #e2bd23;
	font-weight: bold;
	text-decoration: none;
}

.simple-text.center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.simple-text big {
	font-size: 125%;
	font-weight: bold;
}

.forjadores-btn {
	width: fit-content;
	border: none;
	display: inline-block;
	font-size: 1em;
	min-height: 2em;
	color: #686868;
	font-weight: bold;
	line-height: 48px;
	letter-spacing: initial;
	text-transform: uppercase;
	background-color: #e2bd23;
	padding: 0 1.5em;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

.banner {
	color: #FFF;
	font-weight: 400;
	text-align: center;
	background: url(https://www.forjadores.mx/wp-content/uploads/2020/09/Fondo_verde_contactanos.png) no-repeat center center;
	background-size: cover;
	padding: 2.5% 17.5%;
	margin: 0;
	font-size: 2.25em;
}

.banner b {
	color: #e2bd23;
}



/* TABLES */
table.simple-table {
	width: 100%;
	font-size: 1em;
	border-collapse: collapse;
}

table.simple-table,
table.simple-table th,
table.simple-table td {
	border: 2px solid #ddd;
}

table.simple-table th {
	color: #449b51;
	line-height: 1;
	padding: 1em;
	text-align: center;
	position: relative;
}

table.simple-table .over-button-table {
	position: absolute;
	top: -48px;
	left: 0px;
	width: 100%;
	padding: 15px;
	border: 2px solid #eeeeee;
	box-sizing: border-box;
	color: #e2bd23;
	text-decoration: underline;
	font-weight: bold;
	background-color: transparent;
	font-size: 14px;
}

table.simple-table th big {
	font-size: 150%;
}

table.simple-table th br,
table.simple-table td br {
	display: none;
}

table.simple-table td {
	line-height: 1;
	padding: 0.5em;
	color: #5B5B5F;
	text-align: center;
}

table.simple-table td.empty-cell {
	background-color: #efefef;
}

/* TABLE CAT */
table.cat-table {
	width: 100%;
	max-width: initial;
	font-size: 0.75em;
	margin: 0 0 1em;
	border-collapse: collapse;
	color: #5B5B5F;
}

table.cat-table,
table.cat-table th,
table.cat-table td {
	border: 2px solid #ddd;
}

table.cat-table th {
	/* width: -webkit-calc(100% / 12);
	width: calc(100% / 12); */
	/* min-width: 120px; */
	width: fit-content;
	color: #449b51;
	text-align: center;
	line-height: 1.25;
	padding: 0.5em;
}

table.cat-table td {
	width: -webkit-calc(100% / 12);
	width: calc(100% / 12);
	line-height: 1.25;
	padding: 0.5em;
}

table.cat-table td:nth-of-type(2),
table.cat-table td:nth-of-type(3),
table.cat-table td:nth-of-type(5),
table.cat-table td:nth-of-type(6),
table.cat-table td:nth-of-type(7) {
	font-size: 1.15em !important;
}


table.cat-table th:nth-of-type(8),
table.cat-table td:nth-of-type(8) {
	width: -webkit-calc(100% / 6);
	width: calc(100% / 6);
}

/* TABLE MODAL */

.table-modal {
	border-collapse: collapse;
	border: 1px solid #ddd;
}

.table-modal td {
	border: 1px solid #333;
	padding: 8px;
}

.table-modal td:first-child {
	background-color: #777777;
	color: #fff;
	width: 300px;
	text-align: center;
}

.table-modal td:last-child {
	background-color: #d7d7d7;
}

@media only screen and (max-width: 768px) {
	.banner {
		font-size: 33px !important;
		padding: 35px !important;
	}

	.encabezado {
		padding: 35px !important;
	}

	.table-modal td:first-child {
		width: 200px;
	}

	.table-scroll {
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
	}

	table.cat-table th {
		min-width: 90px;
	}

	table.simple-table th {
		min-width: 200px;
	}

	.table-container {
		padding: 10px !important;
	}
}

@media only screen and (max-width: 600px) {
	.banner {
		font-size: 25px !important;
		padding: 10%;
	}

	.encabezado {
		padding: 0px !important;
	}

	.forjadores-btn {
		font-size: 12px !important;
	}
}