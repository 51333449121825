.costos-comisiones-container {
    padding: 0 2.5vw;
    box-sizing: border-box;
}

.title-1 {
    font-size: 2.5em;
    font-weight: 600;
    width: fit-content;
    margin: 2.5% auto 0px;
    margin-bottom: 22px;
}

.title-1 img {
    width: 1em;
    height: 1em;
}

.title-2 {
    font-size: 2.5em;
    font-weight: 700;
    text-transform: uppercase;
    color: #e2bd23;
    margin-bottom: 22px;
}

.costos-comisiones-container table {
    max-width: 100%;
    margin-bottom: 22px;
}

.subtitulo-1 {
    color: #C00000;
    font-weight: 700;
    text-align: center;
    margin: 0;
    font-size: 2.5em;
}

.subtitulo-1 b {
    color: #449b51;
    font-weight: 700;
    letter-spacing: -0.05em;
}

.subtitulo-2 {
    font-size: calc(1.25em * 1.5);
    font-weight: 700;
    text-align: center;
    width: 100%;
}

.subtitulo-3 {
    font-size: 2em;
    font-weight: 600;
    width: 100%;
    color: #449b51;
    margin-bottom: 10px;
}

.table-container {
    border: 2px solid #eeeeee;
    padding: 20px 50px;
    box-sizing: border-box;
}

.w-2-3 {
    width: 66.6666666667%;
}

.ipab {
    width: 70%;
    margin: 50px auto
}

.p-big {
    padding: 0px 100px;
    margin-top: 50px;
}

@media only screen and (max-width: 768px) {
    /* .title-1 {
        font-size: 27px;
    }

    .title-2 {
        font-size: 34px;
    }

    .subtitulo-1 {
        font-size: 34px;
    }

    .subtitulo-2 {
        font-size: 25px;
    }

    .subtitulo-3 {
        font-size: 27px;
    }

    .simple-text {
        font-size: 17px;
    } */

    .w-2-3 {
        width: 100%;
    }

    .ipab {
        width: 100%;
        margin: 0px auto 50px auto;
        flex-direction: column;
    }

    .ipab>.simple-text {
        text-align: justify;
    }

    .ipab img {
        margin: auto;
    }

    .p-big {
        padding: 0px;
    }
}