.contacto {
    width: 100%;
}

.contact-container {
    padding: 2.5% 10.5%;
    ;
}

.info-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.info-container h2 {
    color: #e2bd23;
    line-height: 1;
    font-weight: 600;
    font-size: 2.25em;
}

.info-container .highlighted {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.25em;
    font-weight: bold;
    color: #449b51;
    text-decoration: none;
    line-height: 1.5;
}

.info-container .info-title {
    color: #686868;
    font-size: 1.25em;
}

.info-container .info-icon {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.contact-container .form-container {
    width: 50%;
}

.contact-container .form-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-container .form-container .form-item {
    width: 50%;
}

.contact-container .form-container label {
    font-size: 1em;
    color: #686868;
    display: block;
    margin-bottom: 3px;
}

.contact-container .form-container label .erf-required {
    color: #f00;
    font-weight: 400;
    margin-left: 3px;
}

.contact-container .form-container input,
.contact-container .form-container select,
.contact-container .form-container textarea {
    width: 100%;
    height: 40px;
    font-size: 1em;
    font-weight: 500;
    color: #686868;
    font-weight: 500;
    background-color: rgba(226, 189, 35, 0.5);
    border: none;
    padding: 6px 10px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (max-width: 768px) {


    .contact-container {
        flex-direction: column;
        padding: 35px;
    }

    .contact-container .info-container,
    .contact-container .form-container {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .contacto .info-container h2 {
        font-size: 30px !important;
    }

    .contacto .info-container .highlighted,
    .contacto .info-container .info-title {
        font-size: 14px !important;
    }

    .contacto .form-container {
        margin-top: 35px;
    }

    .form-container .row {
        flex-direction: column;
    }

    .form-container .row .form-item {
        width: 100%;
    }

    .form-container label,
    .form-container input,
    .form-container select,
    .form-container textarea,
    .form-container .forjadores-btn {
        font-size: 14px !important;
    }
}