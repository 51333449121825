/* MODAL */
.modal {
    position: relative;
    pointer-events: all;
    z-index: 1001;
}

.modal.hide {
    opacity: 0;
    transition: 1s;
    pointer-events: none;
}

.modal.show {
    transition: 1s;
    opacity: 1;
}

.modal.show .modal-content {
    animation: modalContentFadeIn 1s forwards;
}

.modal.hide .modal-content {
    animation: modalContentFadeOut 1s forwards;
}

.modal.show .modal-overlay {
    animation: modalOverlayFadeIn 1s forwards;
}

.modal.hide .modal-overlay {
    animation: modalOverlayFadeOut 1s forwards;
}

/* MODAL CONTENTE ANIMATION */
@keyframes modalContentFadeIn {
    0% {
        opacity: 0;
        margin-top: -100vh;
    }

    99% {
        opacity: 1;
        margin-top: -0px;
    }

    100% {
        display: block;
    }
}

@keyframes modalContentFadeOut {
    0% {
        opacity: 1;
        margin-top: -0px;
    }

    99% {
        opacity: 0;
        margin-top: -100vh;
    }

    100% {
        opacity: 0;
        margin-top: -100vh;
        display: none;
    }
}

/* modal-overlay ANIMATION */
@keyframes modalOverlayFadeIn {
    0% {
        opacity: 0;
    }

    99% {
        opacity: 1;
    }

    100% {
        display: block;
    }
}

@keyframes modalOverlayFadeOut {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.modal .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffbd;
    z-index: 1001;
}

.modal .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80vw;
    max-height: 80vh;
    width: 100%;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, .1);
    background: #EFEFEF;
    border-radius: 10px;
    padding: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1002;
}

.modal .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background-color: transparent;
    padding: 0px;
    border: none;
    text-transform: uppercase;
    font-size: 18px;
}
.modal .close-button img{
    width: 30px;
    height: 30px;
    filter: brightness(0);
}