@charset "utf-8";
h1,
h2 {
  margin: 0;
  padding: 0;
}
a {
  font-size: inherit;
  text-decoration: none !important;
}
p {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90%;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #EAEAEA;
}
.elem-hide {
  display: none !important;
}
.nomedio {
  display: block;
}
.encabezado {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15%;
}
.encabezado img {
  width: 100%;
}
.QuienesSomos {
  width: 100%;
}
.QuienesSomos p {
  font-size: 1.25em;
  font-weight: normal;
  line-height: 1.25;
  padding: 2.5% 10% 0;
}
.QuienesSomos .lineas {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
.NuestraHistoria .aboutTimeline {
  margin-bottom: 2.5% !important;
}
.NuestraHistoria .aboutTimeline h2 {
  color: #e2bd23;
  text-align: center;
  margin: 2.5% 0 0;
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.NuestraHistoria .aboutTimeline img:first-of-type {
  display: block;
  width: 40%;
  margin: 0 auto;
}
.NuestraHistoria .aboutTimeline .container {
  padding: 2.5% 10%;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 10%;
  -moz-column-gap: 10%;
  column-gap: 10%;
}
.NuestraHistoria .aboutTimeline .container p {
  font-size: 1.25em;
  font-weight: normal;
  line-height: 1.25;
  margin-bottom: 2.5%;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  break-inside: avoid-column;
}
.NuestraHistoria .aboutTimeline .container p big {
  display: block;
  color: #e2bd23;
  font-weight: 700;
  margin-bottom: 1.25%;
}
.Compromiso .aboutCommitment h2 {
  color: #FFF;
  text-align: center;
  background: url('../assets/images/Encabezados/compromiso_social.jpg') no-repeat center center;
  background-size: cover;
  padding: 5%;
  margin: 0;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.25em;
}
.Compromiso .aboutCommitment p {
  display: inline-block;
  width: 40%;
  vertical-align: top;
  font-size: 1.25em;
  font-weight: normal;
  line-height: 1.25;
  padding: 2.5% 5% 0;
}
.Compromiso .aboutCommitment p big {
  display: block;
  position: relative;
  color: #e2bd23;
  font-weight: 700;
  margin-bottom: 2.5%;
}
.Compromiso .aboutCommitment p big:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.5em;
  left: -0.75em;
  height: 0.5em;
  top: 0.25em;
  background-color: #e2bd23;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.Compromiso .aboutCommitment p:last-of-type {
  width: 100%;
  margin: 0 0 2.5%;
}
.Compromiso .aboutCommitment p b {
  display: block;
  position: relative;
  padding-left: 0.75em;
  margin-top: 2.5%;
}
.Compromiso .aboutCommitment p b:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0.5em;
  left: 0;
  height: 0.5em;
  top: 0.25em;
  background-color: #e2bd23;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.RelacionInversionistas {
  width: 100%;
}
.RelacionInversionistas .informationFinancial,
.RelacionInversionistas .informationCorporate {
  text-align: center;
  padding: 1.25% 5% 2.5%;
}
.RelacionInversionistas .informationFinancial h2,
.RelacionInversionistas .informationCorporate h2 {
  color: #e2bd23;
  margin: 0 0 0.5em;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 2.25em;
  line-height: 1.25;
  letter-spacing: initial;
}
.RelacionInversionistas .informationFinancial a,
.RelacionInversionistas .informationCorporate a {
  display: inline-block;
  width: -webkit-calc(100% / 5);
  width: calc(100% / 5);
  vertical-align: top;
  color: #686868;
  font-weight: normal;
  padding: 0 5%;
  margin-bottom: 2.5%;
}
.RelacionInversionistas .informationFinancial img,
.RelacionInversionistas .informationCorporate img {
  display: block;
  width: 50%;
  margin: 0 auto;
}
.RelacionInversionistas .informationContact {
  text-align: center;
  margin: 1em 0;
}
.RelacionInversionistas .informationContact p {
  line-height: 48px;
}
.RelacionInversionistas .informationContact p a {
  display: inline-block;
  color: #e2bd23;
  font-weight: bold;
}
.TrabajaNostros {
  width: 100%;
}
.TrabajaNostros .entry-content {
  font-size: 1em !important;
  line-height: 1;
}
.TrabajaNostros .entry-content:after {
  clear: both;
  content: "";
  display: block;
}
.TrabajaNostros .entry-content .joinIntro {
  width: 100%;
  max-width: initial;
}
.TrabajaNostros .entry-content .joinIntro img {
  width: 100%;
  margin: 0 0 2.5vw;
}
.TrabajaNostros .entry-content .joinDescription {
  width: 100%;
  max-width: initial;
}
.TrabajaNostros .entry-content .joinDescription h2 {
  font-family: "Ubuntu";
  max-width: initial;
  color: #FFF;
  font-size: 2.25em;
  font-weight: normal;
  text-align: center;
  background: url('../assets/images/Encabezados/fondo_verde_frase.jpg') no-repeat center center;
  background-size: cover;
  padding: 2.5% 25%;
  margin: 0 0 2.5%;
}
.TrabajaNostros .entry-content .joinDescription h2 b {
  color: #e2bd23;
}
.TrabajaNostros .entry-content .joinDescription p {
  text-align: center;
  padding: 0 20% 2.5%;
}
.TrabajaNostros .entry-content .QR {
  width: 100%;
  max-width: initial;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: table;
}
.TrabajaNostros .entry-content .QR img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 500px;
}
.TrabajaNostros .entry-content .QR .forjadores-btn {
  display: inline-block;
  min-height: 2em;
  color: #686868;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: initial;
  text-transform: uppercase;
  background-color: #e2bd23;
  padding: 0 1.5em;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.TipsMejorar .tipsContainer {
  width: 100%;
  max-width: initial;
}
.TipsMejorar .tipsContainer h1 {
  text-align: center;
  margin: 5% 0;
}
.TipsMejorar .tipsContainer ul li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.TipsMejorar .tipsContainer .display-posts-listing {
  margin: 0;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 5%;
  -moz-column-gap: 5%;
  column-gap: 5%;
  letter-spacing: -1em;
  padding: 0 10%;
  margin: 0 0 5%;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item .image {
  display: block;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item .image img {
  width: 580px;
  height: 480px;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item .title {
  display: block;
  color: #e2bd23;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0 0 0.5em;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item .excerpt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: initial;
  line-height: 1.5;
}
.TipsMejorar .tipsContainer .display-posts-listing .listing-item .excerpt .excerpt-more {
  display: inline-block;
  color: #e2bd23;
  line-height: 48px;
}
.Cinconsejos {
  letter-spacing: -1em;
}
.Presupuesto p {
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.Presupuesto .img-graf1 {
  display: inline-block;
  width: 30% !important;
  vertical-align: middle;
  padding: 0 5% 0 7.5%;
  margin: 0 !important;
}
.Presupuesto .text-ri {
  display: inline-block;
  width: 45% !important;
  vertical-align: middle;
  padding: 0 7.5% 0 5%;
}
.Presupuesto > p big {
  display: block;
  margin: 5% 0 !important;
}
.Presupuesto > p big strong {
  color: #449b51;
}
.Presupuesto .img-graf2 {
  margin-bottom: 4rem;
  margin-top: 4rem;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
.Presupuesto .img-tbls {
  margin-bottom: 4rem;
  margin-top: 4rem;
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
.Presupuesto .post-list-half {
  margin: 0;
  display: inline-block;
  width: 45% !important;
  vertical-align: top;
  letter-spacing: initial;
  padding: 0 2.5%;
  margin-bottom: 1em;
}
.Presupuesto .post-list-half li {
  position: relative;
  color: #686868;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.25;
  letter-spacing: initial;
  list-style: none;
  margin: 0.5em 0;
}
.wp-block-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
.wp-block-embed figcaption,
.wp-block-image figcaption {
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1.6rem;
  color: #6d6d6d;
  margin-bottom: 0;
  margin-top: 1.5rem;
  display: block;
  font-weight: 500;
  line-height: 1.2;
}
/* blog entry */
.type-post .featured-media {
  display: none;
}
.type-post .entry-content {
  letter-spacing: -1em;
  padding: 0 2.5%;
}
.type-post .entry-content > .wp-block-image:first-of-type {
  width: 100vw !important;
  margin: 0 0 2.5% -2.5vw !important;
}
.type-post .entry-content > .wp-block-image.post-img-centered img {
  display: block;
  width: 55% !important;
  margin: 0 auto;
}
.type-post .entry-content > .wp-block-image.post-p-right {
  display: inline-block;
  width: 30% !important;
  vertical-align: middle;
  padding: 0 5%;
  margin: 0 !important;
}
.type-post .entry-content > .wp-block-image.post-img-line {
  margin: 5% 0 !important;
}
.type-post .entry-content > p big {
  display: block;
  margin: 5% 0 !important;
}
.type-post .entry-content > p big strong {
  color: #449b51;
}
.post-step {
  display: inline-block;
  width: -webkit-calc(100% / 3) !important;
  width: calc(100% / 3) !important;
  vertical-align: top;
  padding: 2.5%;
}
.post-step strong:nth-of-type(1) {
  display: block;
  position: relative;
  color: #005623;
  font-size: 2em;
  margin-bottom: 0.5em !important;
}
.post-step strong:nth-of-type(1):after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 2.5em;
  height: 0.1em;
  left: 0;
  bottom: 0;
  background-color: #606060;
}
.post-step strong:nth-of-type(2) {
  display: block;
  font-size: 1.25em;
  margin-bottom: 0.25em;
}
.post-img-left {
  display: inline-block;
  width: 50% !important;
  vertical-align: middle;
  padding: 0 5%;
}
.post-list-half {
  display: inline-block;
  width: 45% !important;
  vertical-align: top;
  letter-spacing: initial;
  padding: 0 2.5%;
  margin-bottom: 1em;
}
/* Tables ------------------------------------ */
table {
  border: 0.1rem solid #dcd7ca;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  font-size: 1.6rem;
  margin: 4rem 0;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
}
.alignleft > table {
  margin: 0;
}
.alignright > table {
  margin: 0;
}
th,
td {
  border: 0.1rem solid #dcd7ca;
  line-height: 1.4;
  margin: 0;
  overflow: visible;
  padding: 0.5em;
}
caption {
  background: #dcd7ca;
  font-weight: 600;
  padding: 0.5em;
  text-align: center;
}
thead {
  vertical-align: bottom;
  white-space: nowrap;
}
th {
  font-weight: 700;
}
.type-post .entry-content > .wp-block-table {
  letter-spacing: initial;
  padding: 0 10%;
}
.type-post .entry-content > .wp-block-table table {
  font-size: 1em;
}
.type-post .entry-content > .wp-block-table td {
  border-color: #e2bd23;
  border-width: 0.125em;
}
.post-title-greenbg {
  width: 100vw !important;
  color: #FFF !important;
  font-size: 1.5em !important;
  text-align: center;
  background: url("../assets/images/Encabezados/Fondo_verde_contactanos.png") no-repeat center center;
  background-size: cover;
  padding: 2.5% 15%;
  margin-left: -2.5vw !important;
}
.post-closure {
  color: #449b51 !important;
  font-size: 1.75em !important;
  text-align: center;
  padding: 0 7.5%;
  margin-top: 2.5% !important;
}
.entrys {
  letter-spacing: -1em;
  padding: 0 2.5%;
}
.entrys .lineas {
  margin: 5% 0 !important;
}
.banner-postss {
  width: 100vw !important;
  margin: 0 0 2.5% -2.5vw !important;
}
.banner-postss img {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  vertical-align: bottom;
}
.post-title-greenbg {
  width: 100vw !important;
  color: #FFF !important;
  font-size: 1.5em !important;
  text-align: center;
  background: url('../assets/images/Encabezados/Fondo_verde_contactanos.png') no-repeat center center;
  background-size: cover;
  padding: 2.5% 3%;
  margin-left: -2.5vw !important;
}
.posts {
  padding: 0 2.5%;
  font-size: 1em !important;
  line-height: 1;
}
.post-step {
  display: inline-block;
  width: -webkit-calc(85% / 3) !important;
  width: calc(85% / 3) !important;
  vertical-align: top;
  padding: 2.5%;
  color: #686868;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: initial;
  margin: 0;
}
.post-step strong:nth-of-type(1) {
  display: block;
  position: relative;
  color: #005623;
  font-size: 2em;
  margin-bottom: 0.5em !important;
}
.post-step strong:nth-of-type(1):after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 2.5em;
  height: 0.1em;
  left: 0;
  bottom: 0;
  background-color: #606060;
}
.post-step strong:nth-of-type(2) {
  display: block;
  font-size: 1.25em;
  margin-bottom: 0.25em;
}
.post-closure {
  color: #449b51 !important;
  font-size: 1.75em !important;
  text-align: center;
  padding: 0 7.5%;
  margin-top: 2.5% !important;
  letter-spacing: normal;
}
hr.styled-separator {
  background-color: transparent !important;
  border: none;
  height: 0.12rem;
  overflow: visible;
  position: relative;
  background: linear-gradient(to left, currentColor calc(50% - 16px), transparent calc(50% - 16px), transparent calc(50% + 16px), currentColor calc(50% + 16px));
}
hr.styled-separator::before {
  left: calc(50% - 0.5rem);
  background: currentColor;
  content: "";
  display: block;
  height: 1.6rem;
  position: absolute;
  top: calc(50% - 0.8rem);
  transform: rotate(22.5deg);
  width: 0.1rem;
}
hr.styled-separator::after {
  right: calc(50% - 0.5rem);
  background: currentColor;
  content: "";
  display: block;
  height: 1.6rem;
  position: absolute;
  top: calc(50% - 0.8rem);
  transform: rotate(22.5deg);
  width: 0.1rem;
}
.pagination-single {
  width: 90%;
  max-width: initial;
  font-size: 1.25em;
  margin: 5% auto;
}
.pagination-single hr:first-child {
  margin: 0 0 1em;
}
.pagination-single .pagination-single-inner {
  display: block;
  letter-spacing: -1em;
}
.pagination-single .pagination-single-inner a {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  color: #e2bd23;
  letter-spacing: initial;
  line-height: 1.5;
  margin: 0 !important;
}
.pagination-single .pagination-single-inner .next-post {
  flex-direction: row-reverse;
  text-align: right;
}
.pagination-single hr:last-child {
  margin: 1em 0 0;
}
.TipsSeguridad .securityIntro {
  text-align: center;
  padding: 0 0 5%;
}
.TipsSeguridad .securityIntro > img:first-of-type {
  width: 80%;
  margin: 0 auto;
}
.TipsSeguridad .securityIntro a {
  display: inline-block;
  vertical-align: middle;
  color: #e2bd23;
  font-size: 2em;
  line-height: 1.5;
}
.TipsSeguridad .securityIntro a img {
  display: block;
  width: 6em;
  margin: 0 auto;
}
.TipsSeguridad .securityIntro a b {
  display: block;
  color: #449b51;
}
.TipsSeguridad .securityIntro a:last-of-type {
  margin-left: -10vw;
}
.TipsSeguridad .securityIntro > img:last-of-type {
  display: inline-block;
  width: 40%;
  height: 6em;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}
.TipsABM .abmIntro {
  width: 100%;
  max-width: initial;
}
.TipsABM .abmIntro img {
  width: 80%;
  margin: 0 auto;
}
.TipsABM .abmTypes h2 {
  color: #e2bd23;
  text-align: center;
  padding: 0 10%;
  margin: 2.5% 0 0;
}
.TipsABM .abmTypes small {
  color: #686868;
  font-family: "Montserrat";
  font-weight: 400;
}
.TipsABM .abmTypes .info {
  padding: 2.5% 2.5% 2.5% 52.5%;
}
.TipsABM .abmTypes .info span {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  text-align: center;
  padding: 2.5%;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.TipsABM .abmTypes .info span .active {
  -webkit-box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
}
.TipsABM .abmTypes .info span p {
  font-weight: bold;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.TipsABM .abmTypes .info span.active p,
.TipsABM .abmTypes .info span:hover p,
.TipsABM .abmTypes .info span:focus p {
  color: #e2bd23;
}
.TipsABM .abmTypes > p {
  position: absolute;
  width: 50%;
  left: 0;
  top: 50%;
  text-align: justify;
  line-height: 1.5;
  padding: 0 5% 0 10%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  pointer-events: none;
}
.TipsABM .abmTypes > p.active {
  opacity: 1;
  pointer-events: initial;
}
.TipsABM .lines2 {
  display: block;
  width: 50%;
  margin: 0 auto;
}
.TipsABM .abmCampaign {
  text-align: center;
  padding: 5% 25%;
}
.TipsABM .abmCampaign h2 {
  color: #e2bd23;
  margin: 0 0 1em;
}
.TipsABM .abmCampaign video {
  width: 100% !important;
}
.TipsABM .abmAuth {
  background-color: #e2bd23;
  padding: 2.5% 2.5% 0;
  width: 100%;
  max-width: initial;
}
.TipsABM .abmAuth img {
  display: inline-block;
  width: 32%;
  vertical-align: middle;
}
.TipsABM .abmAuth div {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
  color: #000;
  padding: 0 2.5%;
}
.TipsABM .abmAuth h2 {
  text-align: center;
  margin: 0 0 0.5em;
}
.TipsABM .abmAuth p {
  color: #000;
  line-height: 1.5;
}
.TipsABM .abmAuth span {
  display: inline-block;
  max-width: 45%;
  vertical-align: top;
  font-size: 1.125em;
  line-height: 1.5;
  padding: 0 2.5%;
  margin-top: 2em;
}
.TipsABM #abmScam {
  padding: 5% 7.5%;
}
.TipsABM #abmScam img,
.TipsABM #abmScam div:first-of-type {
  display: inline-block;
  width: 45%;
  vertical-align: middle;
  padding: 0 2.5%;
}
.TipsABM #abmScam div {
  padding: 0 2.5%;
}
.TipsABM #abmScam div:last-of-type {
  margin-top: 5%;
}
.TipsABM #abmScam div h2 {
  color: #e2bd23;
  margin: 0 0 0.5em;
}
.TipsABM #abmScam div p {
  font-size: 1.125em;
  text-align: justify;
  line-height: 1.5;
}
.TipsForjadores #bfIntro img {
  padding: 0 20%;
  width: 60%;
}
.TipsForjadores #bfContainer {
  padding: 2.5% 22.5%;
}
.TipsForjadores #bfContainer img {
  display: inline-block;
  width: 15%;
  vertical-align: top;
  padding: 0 2.5%;
  margin-bottom: 2em;
}
.TipsForjadores #bfContainer p {
  display: inline-block;
  width: 80%;
  vertical-align: top;
  font-size: 1.125em;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 2em;
}
@media only screen and (max-width: 768px) {
  .QuienesSomos .encabezado {
    padding: 35px !important;
  }
  .QuienesSomos p {
    font-size: 1em !important;
    text-align: justify;
    padding: 0 5% !important;
  }
  .NuestraHistoria .aboutTimeline h2 {
    font-size: 1.25em;
  }
  .NuestraHistoria .aboutTimeline img:first-of-type {
    width: 66.66%;
  }
  .NuestraHistoria .aboutTimeline .container p {
    font-size: 0.8em;
    margin-bottom: 10%;
  }
  .Compromiso .aboutCommitment h2 {
    font-size: 1.25em;
    padding: 10%;
    margin-bottom: 2.5%;
  }
  .Compromiso .aboutCommitment p {
    font-size: 0.8em;
    width: 100%;
    padding: 2.5% 5%;
  }
  .TrabajaNostros .entry-content .joinDescription h2 {
    font-size: 1.25em;
    padding: 10%;
    margin-bottom: 5%;
  }
  .TrabajaNostros .entry-content .QR .forjadores-btn {
    line-height: 40px;
    font-size: 0.5em;
  }
  .TipsMejorar .tipsContainer h1 {
    text-align: center;
    margin: 5% 0;
  }
  .TipsMejorar .tipsContainer .display-posts-listing .listing-item {
    -webkit-columns: initial;
    -moz-columns: initial;
    columns: initial;
    -webkit-column-gap: initial;
    -moz-column-gap: initial;
    column-gap: initial;
    padding: 0 5%;
  }
  .TipsMejorar .tipsContainer .display-posts-listing .listing-item .image {
    margin-bottom: 2.5%;
  }
  .TipsMejorar .tipsContainer .display-posts-listing .listing-item .image img {
    width: 100%;
    height: auto;
  }
  .TipsMejorar .tipsContainer .display-posts-listing .listing-item .title {
    font-size: 1em;
  }
  .TipsForjadores #bfIntro img {
    padding: 0 5%;
    width: 90%;
  }
  .TipsForjadores #bfContainer {
    padding: 5% 2.5%;
  }
  .TipsForjadores #bfContainer p {
    font-size: 0.65em;
    line-height: 1.5;
  }
  .Cinconsejos .post-step {
    width: 45% !important;
    font-size: 0.8em;
  }
  .pagination-single {
    font-size: 0.9rem;
    margin-top: 8rem;
  }
  .pagination-single a {
    display: inline-block;
    margin: 0 !important;
  }
  .pagination-single hr:first-child {
    margin: 0 0 0.8rem 0;
  }
  .pagination-single .pagination-single-inner {
    flex-direction: row;
    justify-content: space-between;
  }
  .wp-block-image img {
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
  }
  .post-step {
    width: 45% !important;
  }
  .type-post .entry-content > .wp-block-image {
    margin: 5% 0 !important;
  }
  .type-post .entry-content > .wp-block-image.post-img-centered img {
    width: 90%!important;
  }
  .type-post .entry-content > .wp-block-image.post-p-right {
    width: 85% !important;
    padding: 0 7.5% !important;
  }
  .post-img-left {
    width: 85% !important;
    margin-top: 5% !important;
    padding: 0 7.5% !important;
  }
  .post-title-greenbg {
    width: 100vw !important;
    color: #FFF !important;
    font-size: 1em !important;
    padding: 2.5% 0;
  }
  .type-post .entry-content > .wp-block-table table {
    font-size: 0.6em;
  }
  .post-closure {
    font-size: 1em !important;
  }
}
@media (min-width: 700px) {
  .entry-content p,
  .entry-content li {
    line-height: 1.476;
  }
}
@media (max-width: 479px) {
  li {
    margin: 0.5rem 0 0 1rem;
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 600px) {
  .QuienesSomos .encabezado {
    padding: 0px !important;
  }
}
