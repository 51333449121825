.clausulado .simple-text {
    text-align: center;
}

.clausulado .row {
    padding: 0px 17%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
}

.clausulado .descripcion {
    margin-bottom: 22px;
}

.clausulado .clausula {
    width: calc(50% - 10px);
    margin-bottom: 30px;
}

.clausulado .clausula .simple-text {
    width: 65%;
    margin: 0px auto 5px auto;
}

.clausulado .clausula img {
    width: 40%;
    max-height: 150px;
    object-fit: contain;
    display: block;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    .clausulado{
        padding: 0px 5%;
    }
    .clausulado .row{
        padding: 0px;
    }
    .clausulado .clausula img{
        width: 50%;
    }
}