.header {
    position: sticky;
    background-color: #fff;
    width: 100%;
    top: 0;
    left: 0px;
    z-index: 1001;
    box-sizing: border-box;
}

.header #top-bar {
    background-color: #efefef;
    display: flex;
    flex-wrap: nowrap;
    margin: 0px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 7px 1em;
    box-sizing: border-box;
}

.header #top-bar button,
.header #top-bar a {
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    background-color: transparent;
    font-size: 1em;
    font-weight: 500;
    color: #5f5f5f;
}

.header #top-bar button.atencion-clientes {
    color: #e2bd23;
    font-weight: bold;
    margin-left: 0;
    float: left;
}

.header img.icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.header #main-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 3px 19px;
}

.header #main-bar .forjadores-logo {
    height: 98px;
}

.header #main-bar .menu-button {
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.header #main-bar .menu-button span {
    color: #449b51;
    font-weight: 600;
    font-size: 1.25em;
}

.header #main-bar .menu-button .menu-icon {
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 768px) {
    .header #top-bar{
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
    .header #top-bar .row{
        flex-wrap: wrap;
        justify-content: center;
    }
    .header #main-bar .forjadores-logo {
        height: 60px;
    }
    .header #main-bar .menu-button span {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header #top-bar button img{
        width: 20px;
        height: 20px;
    }
    .header #top-bar button{
        font-size: 12px !important;
    }
}