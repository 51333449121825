@charset "utf-8";
h1,
h2 {
  margin: 0;
  padding: 0;
}
a {
  font-size: inherit;
  text-decoration: none !important;
}
p {
  margin: 0;
  padding: 0;
}
body {
  background: #fff;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90%;
  }
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTtw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eA.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Ttw.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTtw.ttf) format('truetype');
}
.scroll {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #EAEAEA;
}
.elem-hide {
  display: none !important;
}
.nomedio {
  display: block;
}
.footer-nav-widgets-wrapper,
#site-footer {
  background-color: #fff;
  border-color: #dedfdf;
  border-style: solid;
  border-width: 0;
}
.footer-top-visible .footer-nav-widgets-wrapper,
.footer-top-hidden #site-footer {
  margin-top: 5rem;
}
.reduced-spacing.footer-top-visible .footer-nav-widgets-wrapper,
.reduced-spacing.footer-top-hidden #site-footer {
  border-top-width: 0.1rem;
}
.footer-top,
.footer-widgets-outer-wrapper,
#site-footer {
  padding: 3rem 0;
}
/* Footer Top -------------------------------- */
.footer-top {
  display: flex;
  border-bottom: 0.1rem solid #dedfdf;
  justify-content: space-between;
}
/* FOOTER MENU */
.footer-menu {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.0277em;
}
.footer-menu li {
  line-height: 1.25;
  margin: 0.25em 0 0 0;
}
.footer-menu a {
  text-decoration: none;
  word-break: normal;
  word-wrap: normal;
}
.footer-menu a:hover,
.footer-menu a:focus {
  text-decoration: underline;
}
/* FOOTER SOCIAL */
.footer-social-wrapper {
  margin: 0;
  width: 100%;
}
.has-footer-menu .footer-social-wrapper {
  flex-shrink: 0;
  margin-left: 1rem;
  width: 50%;
}
ul.footer-social {
  margin: -0.5rem 0 0 -0.5rem;
}
.has-footer-menu .footer-social {
  justify-content: flex-end;
}
ul.footer-social li {
  margin: 0.5rem 0 0 0.5rem;
}
.footer-social a {
  background-color: #cd2653;
  height: 3.6rem;
  width: 3.6rem;
}
.footer-social a::before {
  font-size: 1.6rem;
}
/* Footer Widgets ---------------------------- */
.footer-widgets-outer-wrapper {
  border-bottom: 0.1rem solid #dedfdf;
}
/*
 * FOOTER
 */
.footer-nav-widgets-wrapper {
  background-color: transparent !important;
  padding: 0;
  margin: 0 !important;
  border: none;
}
.footer-inner {
  width: 100%;
  max-width: initial;
  margin: 0;
}
.footer-widgets-outer-wrapper {
  padding: 0;
  border: 0;
}
.footer-widgets-wrapper {
  display: block;
  width: 100%;
  max-width: initial;
  margin: 0;
}
.footer-widgets {
  width: 100%;
  margin: 0;
}
.widget {
  margin: 0 !important;
}
.widget-content {
  font-size: 1em;
}
.menu-mainu-container {
  background-color: #5b3f0f !important;
  padding: 1.25% 1.25% 0;
  border-top: 1em solid #4d340f;
}
.menu-mainu-2 {
  width: 100%;
  letter-spacing: -1em;
  text-align: center;
}
.menu-mainu-2 > li {
  display: inline-block;
  max-width: calc(100% / 9);
  min-width: auto;
  vertical-align: top;
  letter-spacing: initial;
  padding: 0 1.25%;
  margin: 0;
}
.menu-mainu-2 > li a {
  display: inline-block;
  color: #FFF;
  font-size: 0.9em;
  line-height: 1.25;
  margin-bottom: 0.5em;
  font-weight: bold;
}
.menu-mainu-2 > li ul {
  list-style: none;
}
.menu-mainu-2 > li .sub-menu li {
  margin: 0;
}
.menu-mainu-2 > li .sub-menu li a {
  display: inline-block;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 0.5em;
}
.menu-social {
  background-color: #5b3f0f !important;
  text-align: right;
  padding: 0 3.75% 1.25%;
}
.menu-social li {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
.menu-social li:first-of-type:before {
  content: "Redes Sociales";
  display: inline-block;
  vertical-align: middle;
  color: #FFF;
  line-height: 40px;
  margin-right: 0.5em;
}
.menu-social li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  color: transparent;
}
#menu-item-184 a {
  display: inline-block;
  vertical-align: middle;
  background: url("https://www.forjadores.mx/wp-content/uploads/2020/06/ms_facebook.png") no-repeat center center;
  background-size: 100% 100%;
}
#menu-item-185 a {
  background: url("https://www.forjadores.mx/wp-content/uploads/2020/06/ms_instagram.png") no-repeat center center;
  background-size: 100% 100%;
}
.menu-legal {
  text-align: center;
  background-color: #d3d3d2;
  padding: 1.25%;
}
.menu-legal li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  margin: 0;
}
.menu-legal li a {
  color: #FFF;
  font-size: 0.8em;
  font-weight: 500;
}
.footerLogos {
  text-align: center;
  background-color: #d3d3d2;
  padding: 0 1.25% 1.25%;
}
.footerLogos > a {
  display: inline-block;
  vertical-align: middle;
}
.footerLogos > a img {
  width: 5em;
}
.footerLogos p {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.6em;
  line-height: 1.5;
}
.footerLogos p a {
  color: #686868;
}
@media (max-width: 767px) {
  .column-one .widget_nav_menu:first-of-type {
    display: none;
  }
  .menu-social {
    text-align: center;
    padding: 2.5% 5%;
    border-top: 1em solid #4d340f;
  }
  .menu-legal {
    padding: 10px 5% 0;
  }
  .menu-legal li {
    display: block;
    padding: 0;
  }
  .menu-legal li a {
    display: inline-block;
    font-size: 12px;
    line-height: 40px;
  }
  .footerLogos > a {
    width: 28%;
    letter-spacing: initial;
    padding: 0 2.5%;
  }
  .footerLogos > a img {
    width: 100% !important;
    margin: 0.5em 0;
  }
  .footerLogos > p {
    display: inline-block;
    width: 55%;
    vertical-align: middle;
    font-size: 12px;
    letter-spacing: initial;
    padding: 0 2.5%;
    margin: 0;
  }
}
